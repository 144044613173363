import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getBaseUrl } from '@/redux/modules/config';
import { useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import PageTitle from '@/components/PageTitle/PageTitle';
import styled from 'styled-components';

const NotFound = () => {
    const baseUrl = useAppSelector(getBaseUrl);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const badUrl = queryParams.get('badUrl');

    return (
        <StyledNotFound>
            <StyledPageTitle title="404" />
            <p>
                <FormattedMessage id="sorry_we_cant_find" />
            </p>
            {Boolean(badUrl) && (
                <StyledP>
                    <StyledI>
                        &quot;
                        {`${baseUrl}${badUrl}`}
                        &quot;
                    </StyledI>
                </StyledP>
            )}
            <StyledP>
                <Link to={baseUrl}>{baseUrl}</Link>
            </StyledP>
        </StyledNotFound>
    );
};

export default NotFound;

const StyledNotFound = styled.div`
    margin: 60px 0;
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledPageTitle = styled(PageTitle)`
    text-align: center;
    font-size: 100px;
    line-height: 100px;
`;

const StyledP = styled.p`
    margin-top: 0;
`;

const StyledI = styled.i`
    color: ${({ theme }) => theme.colors.grey200};
`;
