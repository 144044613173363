import * as React from 'react';
import { H1 } from '@liveauctioneers/hammer-ui-core/text';
import { safelyRender } from '@/utils/safelyRender';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

type Props = {
    callToAction?: React.ReactNode;
    className?: string;
    title: React.ReactNode;
};

const PageTitle = ({ callToAction, className, title, ...rest }: Props) => {
    if (!title) {
        return null;
    }
    return (
        <StyledTitle>
            <StyledTextH1
                className={className}
                data-testid="pageTitle"
                {...rest}
            >
                {typeof title === 'string' ? safelyRender({ html: title }) : title}
            </StyledTextH1>
            {Boolean(callToAction) && <StyledCallToAction>{callToAction}</StyledCallToAction>}
        </StyledTitle>
    );
};

export default React.memo<Props>(PageTitle, isEqual);

const StyledTitle = styled.section`
    display: flex;
`;

const StyledTextH1 = styled(H1)`
    && {
        display: block;
        margin: 32px 0 24px;
    }
`;

const StyledCallToAction = styled.span`
    margin: auto 0 auto 30px;
`;
